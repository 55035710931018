/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "./store/auth/auth.selector";
import { ProtectedRoute } from "./routes/protected.routes";
import { SearchRoute } from "./routes/search.routes";
import { LotRoute } from "./routes/lot.routes";
import { PiecesRoute } from "./routes/pieces.routes";
import Projects from "./pages/Projects/Projects.page";
import SignIn from "./pages/SignIn/SignIn.page";
import Video from "./pages/Video/Video.page";
import Videos from "./pages/Videos/Videos.page";
import Scales from "./pages/Scales/Scales.page";
import Already from "./pages/Already/Already.page";
import Search from "./pages/Search/Search.page";
import Rotation from "./pages/Rotation/Rotation.page";
import Contact from "./pages/Contact/Contact.page";
import Available from "./pages/Available/Available.page";
import Results from "./pages/Results/Results.page";
import Plan from "./pages/Plan/Plan.page";
import Lot from "./pages/Lot/Lot.page";
import Pieces from "./pages/Pieces/Pieces.page";
import Piece from "./pages/Piece/Piece.page";
import Prestation from "./pages/Prestation/Prestation.page";
import SignInQRCode from "./pages/SignInQRCode/SignInQRCode.page";
import QRCodePage from "./pages/QRCode/QRCode.page";

import "./styles/main.scss";

/*//////////////////////*/
/* Utils & Reducers
/*/ /////////////////////*/
import { emptyVideo, initSockets } from "./utils/websockets.utils";
import { setProjects, setVideos } from "./store/projects/projects.actions";
import { setContent } from "./store/content/content.actions";
import { getUserInfo } from "./store/auth/auth.actions";
import { expulsion, setCursor } from "./store/auth/auth.reducer";

const LotRoutes = ({ socket }) => (
  <>
    <Route exact path="" element={<Lot />} />
    <Route
      path="video/:videoID"
      element={<Video socket={socket} lotRoute={true} />}
    />
    <Route path="pieces" element={<PiecesRoute socket={socket} />}>
      <Route exact path="" element={<Pieces />} />
      <Route path=":pieceID" element={<Piece socket={socket} />} />
      <Route
        path=":pieceID/prestation/:prestationID"
        element={<Prestation socket={socket} />}
      />
    </Route>
  </>
);

const App = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hideCursor = queryParams.get("hideCursor");
  const { cursor } = useSelector(getAuthReducer);

  const { userID, userToken, socketID, singleProject } =
    useSelector(getAuthReducer);
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  /* scroll haut page */
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  /* user settings & websockets */
  useEffect(() => {
    if (userID && userToken && socketID) {
      setLoading(true);
      const triggerDispatchs = async () => {
        dispatch(getUserInfo({ userToken, userID }))
          .then(() => dispatch(setProjects({ userID, userToken })))
          .then(() => dispatch(setVideos({ userToken })))
          .then(() => setSocket(initSockets(socketID)))
          .then(() => dispatch(setContent()))
          .then(() => setLoading(false));
      };
      triggerDispatchs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, userToken, socketID]);

  /* socket events */
  useEffect(() => {
    if (socket) {
      emptyVideo(socketID);
      /* Utilisateur déjà présent */
      socket.on("already", alreadyEvent);
      /* Nouvelle connexion de l'application */
      socket.on("appStart", appStartEvent);
      /* Expulsion utilisateur */
      socket.on("expulse", expulseEvent);
      /* Erreur connexion */
      socket.on("connect_error", connectError);
      /* RFID */
      socket.on("rfid", updateScale);
    }
    return () => {
      if (socket) {
        socket.off("already", alreadyEvent);
        socket.off("appStart", appStartEvent);
        socket.off("expulse", expulseEvent);
        socket.off("connect_error", connectError);
        socket.off("rfid", updateScale);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  /* vide content quand quitte la page */
  useEffect(() => {
    return () => emptyVideo(socketID);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hideCursor) {
      dispatch(setCursor(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCursor]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  const alreadyEvent = () => navigate("/already");

  const appStartEvent = () => {
    if (!singleProject) {
      navigate("/");
      emptyVideo(socketID);
    }
  };

  const expulseEvent = () => {
    emptyVideo(socketID);
    socket.disconnect();
    dispatch(expulsion());
  };

  const connectError = (error) => {
    console.log(`Message erreur: ${error.message}`);
  };

  const updateScale = ({ project, scale }) => {
    navigate(`/project/${project}/scale/${scale}`);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className={cursor === false ? "cursor--hide" : ""}>
      <Routes>
        {/* SignIn Route */}
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/qrcode-signin" element={<SignInQRCode />} />

        {/* Protected Routes */}
        <Route
          element={<ProtectedRoute userToken={userToken} socket={socket} />}
        >
          <Route path="already" element={<Already />} />
          <Route
            exact
            path="/"
            element={<Projects loading={loading} socket={socket} />}
          />

          <Route path="project/:projectID">
            <Route exact path="" element={<Scales socket={socket}/>} />
            <Route path="scale/:scaleID" element={<Videos socket={socket} />} />
            <Route path="plan" element={<Plan />} />
            <Route path="rotation/:scaleID" element={<Rotation socket={socket}/>} />

            {/* Programme Routes */}
            <Route
              path="plan/:lotID"
              element={<LotRoute userToken={userToken} />}
            >
              {LotRoutes({ socket })}
            </Route>

            <Route
              path="scale/:scaleID/video/:videoID"
              element={<Video socket={socket} />}
            />

            {/* Search Routes */}
            <Route path="scale/:scaleID/search" element={<SearchRoute />}>
              <Route path="form" element={<Search socket={socket} />} />
              <Route path="results" element={<Results socket={socket} />} />

              {/* Lot Routes */}
              <Route
                path="results/:lotID"
                element={<LotRoute userToken={userToken} />}
              >
                {LotRoutes({ socket })}
              </Route>
            </Route>
          </Route>

          <Route path="contact" element={<Contact socket={socket} />} />
          <Route path="available" element={<Available socket={socket} />} />
          <Route path="qrcode" element={<QRCodePage />} />
        </Route>

        {/* 404 Routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default App;
