import io from "socket.io-client";

export const initSockets = (room, currentSocket = "socket") => {
  const url = process.env.REACT_APP_STRAPI;
  window[currentSocket] = io(url, {
    transports: ["polling", "websocket"],
  });
  window[currentSocket].io.on("reconnect", () => {
    if (window[currentSocket]) {
      console.log("reconnect");
      window[currentSocket].emit("adminroom", room);
    }
  });
  window[currentSocket].emit("adminroom", room);
  return window[currentSocket];
};

export const reconnectRoom = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("adminroom", room);
};

export const resetSockets = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("emptyContent", room);
  window[currentSocket]?.removeAllListeners(room);
};

export const loopVideo = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("loopRestart", room);
};

export const emptyVideo = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("emptyContent", room);
};

export const setSocketsForceRoom = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("forceRoom", room);
};

export const sendTest = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("test", room);
};

export const logoutUser = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("logout", room);
  window[currentSocket]?.disconnect();
};

export const disconnectUser = (room, currentSocket = "socket") => {
  window[currentSocket]?.disconnect();
  window[currentSocket] = null;
};

export const setSocketsReloadContent = (
  videoName,
  type,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("reloadContent", videoName, type, room);
};

export const setSocketsNewImages = (
  images,
  programme,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("images", images, programme, room);
};

export const setSocketsSearchSelected = (
  image,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("searchSelected", image, room);
};

export const setSocketsSearchImages = (
  images,
  programme,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("searchImages", images, programme, room);
};

export const setSocketsSearchImagesVisible = (
  images,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("searchImagesVisible", images, room);
};

export const setSocketsNewContent = (
  videoName,
  type,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("newContent", videoName, type, room);
};

export const setSocketsNewScaleReady = (
  videoName,
  room,
  currentSocket = "socket"
) => {
  
  window[currentSocket]?.emit("newScaleReady", videoName, room);
};

export const setSocketsNewContentWithInitialValue = (
  videoName,
  type,
  initialValue,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit(
    "newContentWithInitialValue",
    videoName,
    type,
    initialValue,
    room
  );
};

export const setSocketsLogoURL = (logoUrl, room, currentSocket = "socket") => {
  window[currentSocket]?.emit("logoApp", logoUrl, room);
};

export const setSocketsSearchBackground = (
  logoSearch,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("logoSearch", logoSearch, room);
};

export const setSocketsVideoGoTo = (
  time,
  discret,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("videoGoTo", time.toFixed(2), discret, room);
};

export const setSocketsVideoTimer = (
  time,
  timer,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("videoTimer", time, timer, room);
};

export const setSocketsContentGoToPercent = (
  percent,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("contentGoToPercent", percent, room);
};

export const setSocketsContentIs3D = (is3D, room, currentSocket = "socket") => {
  window[currentSocket]?.emit("contentIs3D", is3D, room);
};

export const setSocketsContentGoToPosition = (
  angle,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("contentGoToPosition", angle, room);
};

export const setSocketsContentGoToPositionVertical = (
  angle,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("contentGoToPositionVertical", angle, room);
};

export const setSocketsContentGoToFinalPosition = (
  angle,
  room,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("contentGoToFinalPosition", angle, room);
};

export const setSocketsVideoPlay = (play, room, currentSocket = "socket") => {
  window[currentSocket]?.emit(play ? "videoPlay" : "videoPause", room);
};

export const setSocketsAutoPlay = (videos, room, currentSocket = "socket") => {
  window[currentSocket]?.emit("autoPlayVideos", videos, room);
};

export const setSocketsAutoStop = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("autoStop", room);
};

export const sendEmail = (
  email,
  content,
  replyTo,
  currentSocket = "socket"
) => {
  window[currentSocket]?.emit("email", email, content, replyTo);
};

// CGR
// Appel rotation moteur
export const setSocketsRotateTo = (scaleID, angle, videoName, room, currentSocket = "socket") => {
  window[currentSocket]?.emit("rotateTo", scaleID, angle, videoName, room)
};

// Appel cas Bijou -> video & rotor loop
export const setSocketStartBijou = (room, currentSocket = "socket") => {
  window[currentSocket]?.emit("startBijou", room)
};

