/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProjects,
  getProjectsReducer,
  getScales,
  getVideos,
} from "../../store/projects/projects.selector";
import { setSocketStartBijou } from "../../utils/websockets.utils";
import { getContentReducer } from "../../store/content/content.selector";
import { setLastPage } from "../../store/history/history.reducer";
import Element from "../../components/Element/Element.component";
import BackButton from "../../components/BackButton/BackButton.component";
import Empty from "../../components/Empty/Empty.component";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { getPlan, getProjectsRoutes } from "../../utils/helpers.utils";

const Scales = ({socket}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectID } = useParams();
  const {
    texts: { textScales, scalesEmpty },
  } = useSelector(getContentReducer);
  const projects = useSelector(getProjects);
  const videos = useSelector(getVideos);
  const { singleProject, socketID } = useSelector(getAuthReducer);
  const scales = useSelector(getScales(projectID));
  const projectsReducer = useSelector(getProjectsReducer);
  const [isUniqueProject, setIsUniqueProject] = useState(false);
  const plan = useMemo(
    () => getPlan(projectsReducer, projectID),
    [projectID, projectsReducer]
  );
  const projectsRoutes = useMemo(
    () => getProjectsRoutes(projects, videos),
    [projects, videos]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    dispatch(setLastPage("scales"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* redirection si un seul projet */
  useEffect(() => {
    if (singleProject) {
      const currentProject = projectsRoutes.find(
        (project) => project.id === +projectID
      );
      if (
        currentProject?.scales &&
        currentProject.scales.length === 1 &&
        !plan
      ) {
        navigate(`/project/${projectID}/scale/${currentProject.scales[0].id}`);
      }
      if (projectsRoutes.length === 1) {
        setIsUniqueProject(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsRoutes, singleProject, projectID, plan]);

  useEffect(() => {

    const tryBijou = () => {
        const bijouInterval = setInterval(() => {
        if (socket.connected) {
          setSocketStartBijou(socketID)
          clearInterval(bijouInterval)
        }
      }, 100);
    }

    if (socket && projects[0] && projects[0].isRotative) {
      tryBijou() 
    }
  }, [socket, projects])

  // TODO : add if necessary
  // useEffect(() => {
  //   return () => {
  //       if (projects[0] && projects[0].isRotative) {
  //       resetSockets(socketID);
  //     }
  //   }
  // }, [projects])

  
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton text={textScales} link="/" back={!isUniqueProject} />
      {scales && scales.length ? (
        <ul>
          {scales.map((scale) => (
            <li key={scale.id}>
              <Element
                text={scale.name}
                link={`scale/${scale.id}`}
                icon={scale.icon}
              />
            </li>
          ))}

        </ul>
      ) : (
        !plan && <Empty text={scalesEmpty} />
      )}
      {plan && (
        <Element icon={plan.planIcon} text={plan.planName} link={`plan`} />
      )}
    </>
  );
};

export default Scales;
