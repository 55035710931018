/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  setSocketsRotateTo
} from "../../utils/websockets.utils";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader.component";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { getScales } from "../../store/projects/projects.selector";

const Rotation = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const navigate = useNavigate();
  const { projectID, scaleID } = useParams();
  const { socketID } = useSelector(getAuthReducer);
  const scales = useSelector(getScales(projectID));
  const scale = scales.find(s => s.id == scaleID);
  const socketsReady = useRef(false);

  useEffect(() => {
    setSocketsRotateTo(scaleID, scale.AngleForRotativeProject, scale.isReadyVideo, socketID)
  }, [])

  useEffect(() => {
    if (socket && !socketsReady.current) {
      socket.on("rotationOver", rotationEnd);
      socketsReady.current = true;
    }
    return () => {
      if (socket) socket.off("rotationOver", rotationEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const rotationEnd = (scale) => {
    navigate(`/project/${projectID}/scale/${scaleID}`)
  };
  
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
        <div style = {{textAlign : "center", padding : "20px"}}>Waiting for rotation...</div>
        <div className="absolute inset-40 bg-white">
        <div className="relative h-56">
            <Loader />
        </div>
        </div>
    </>
  );
};

export default Rotation;
